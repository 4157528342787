import React, { useRef } from 'react';
import { GalleryContainer } from "./styles";
import galleryBackground from "../../images/criollos/galleryBackground.jpg";
import botonRegresarOff from "../../images/criollos/criollo_boton_regresar_off.png"
import botonRegresarOn from "../../images/criollos/criollo_boton_regresar_on.png"
import CarouselGallery from "./Carousel";
import SmokeAnimation from './SmokeAnimation';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Gallery = ({ galleryImages, handleShowGallery, audio, className, logoResponsive }) => {
  const audioPlayerRef = useRef();
  const PlayPauseVideo = (type) => {
    const Audio = document.getElementsByClassName(`audio-container-${className}`)[0];
    if (type === 1) {
      if (Audio.querySelector('[aria-label="Play"]')) {
        Audio.querySelector('[aria-label="Play"]').click()
      }
    } else {
      if (Audio.querySelector('[aria-label="Pause"]')) {
        Audio.querySelector('[aria-label="Pause"]').click()
      }
    }
  }
  return (
    <>
      <GalleryContainer
        className="animate__animated disableAnimation"
        id={`${className}-gallery-id`}
        background={galleryBackground}
      >
        <SmokeAnimation className="smoke-animation" />
        <button className="gallery-back-button" onClick={handleShowGallery}>
          <img className="firts-image" alt="Criollos-boton-Regresar-Off" src={botonRegresarOff} />
          <img className="last-image" alt="Criollos-boton-Regresar-On" src={botonRegresarOn} />
        </button>
        <div className={`gallery-subcontainer animate__animated ${className}-1`}>
          <CarouselGallery
            className={className}
            galleryImages={galleryImages}
          />
        </div>
        <div className="gallery-logo">
          <img className="no-responsive" alt="Criollos-logo" src={logoResponsive} />
          <img className="responsive" alt="Criollos-logo" src={logoResponsive} />
        </div>
        {audio &&
          <div className={"audio-container audio-container-" + (className)}>
            <AudioPlayer
              showSkipControls={false}
              showJumpControls={false}
              customIcons={{
                play: <button aria-label="Play" className="audio-react-play-control-modify"></button>,
                pause: <button aria-label="Pause" className="audio-react-pause-control-modify"></button>
              }}
              ref={audioPlayerRef}
              src={audio}
            />
          </div>
        }
      </GalleryContainer>
      <button id={`${className}-play`} onClick={() => PlayPauseVideo(1)} style={{ display: 'none' }}>Play</button>
      <button id={`${className}-pause`} onClick={() => PlayPauseVideo(2)} style={{ display: 'none' }}>Pause</button>
    </>
  )
}

export default Gallery
