import React from 'react';
import { PortraitVideoContainer, PortraitMenuContainer } from "./styles";
import MediaPlayer from "./MediaPlayer";
import Titulo from "../../images/criollos/criollo_titulo.png";
import Criollo_boton_barrios_on from "../../images/criollos/criollo_boton_barrios_on.png";
import Criollo_boton_barrios_off from "../../images/criollos/criollo_boton_barrios_off.png";
import Criollo_boton_catedral_off from "../../images/criollos/criollo_boton_catedral_off.png";
import Criollo_boton_catedral_on from "../../images/criollos/criollo_boton_catedral_on.png";
import Criollo_boton_creditos_off from "../../images/criollos/criollo_boton_creditos_off.png";
import Criollo_boton_creditos_on from "../../images/criollos/criollo_boton_creditos_on.png";
import Criollo_boton_lopez_off from "../../images/criollos/criollo_boton_lopez_off.png";
import Criollo_boton_lopez_on from "../../images/criollos/criollo_boton_lopez_on.png";
import Criollo_boton_mapa_off from "../../images/criollos/criollo_boton_mapa_off.png";
import Criollo_boton_mapa_on from "../../images/criollos/criollo_boton_mapa_on.png";
import Criollo_boton_vallejo_off from "../../images/criollos/criollo_boton_vallejo_off.png";
import Criollo_boton_vallejo_on from "../../images/criollos/criollo_boton_vallejo_on.png";

const Portrait = ({ handleChangePage, className }) => {

  return (
    <PortraitVideoContainer className="ActivePage" id={`${className}-id`}>
      <div className="title">
        <img id="criollos-image" alt="Criollos título" src={Titulo} className="animate__animated opacity-0 "/>
        <h1 className="d-none">Criollos</h1>
        <h2 className="d-none">Donde resiste la jarana</h2>
      </div>
      <div className="video animate__animated animate__fadeIn">
        <div className="degree"></div>
        <MediaPlayer/>
      </div>
      <PortraitMenuContainer className="animate__animated opacity-0 animate__fadeInLeft">
        <div className="MenuSubcontainer">
          <button type="button" onClick={() => handleChangePage("Lopez")}>
            <img alt="Criollo-boton-lopez-off" src={Criollo_boton_lopez_off} className="firts-image" />
            <img alt="Criollo-boton-lopez-on" src={Criollo_boton_lopez_on} className="last-image" />
          </button>
          <button type="button" onClick={() => handleChangePage("Barrios")}>
            <img alt="Criollo-boton-barrios-off" src={Criollo_boton_barrios_off} className="firts-image" />
            <img alt="Criollo-boton-barrios-on" src={Criollo_boton_barrios_on} className="last-image" />
          </button>
          <button type="button" onClick={() => handleChangePage("Catedral")}>
            <img alt="Criollo-boton-catedral-off" src={Criollo_boton_catedral_off} className="firts-image" />
            <img alt="Criollo-boton-catedral-on" src={Criollo_boton_catedral_on} className="last-image" />
          </button>
          <button type="button" onClick={() => handleChangePage("Vallejo")}>
            <img alt="Criollo-boton-vallejo-off" src={Criollo_boton_vallejo_off} className="firts-image" />
            <img alt="Criollo-boton-vallejo-on" src={Criollo_boton_vallejo_on} className="last-image" />
          </button>
          <button type="button" onClick={() => handleChangePage("Mapa")}>
            <img alt="Criollo-boton-mapa-off" src={Criollo_boton_mapa_off} className="firts-image" />
            <img alt="Criollo-boton-mapa-on" src={Criollo_boton_mapa_on} className="last-image" />
          </button>
          <button type="button" onClick={() => handleChangePage("Creditos")}>
            <img alt="Criollo-boton-creditos-off" src={Criollo_boton_creditos_off} className="firts-image" />
            <img alt="Criollo-boton-creditos-on" src={Criollo_boton_creditos_on} className="last-image" />
          </button>
        </div>
      </PortraitMenuContainer>
    </PortraitVideoContainer>
  )
}

export default Portrait
