import React from 'react';
import Portrait from './Portrait';
import {
  CriollosContainer
} from "./styles";
import Exposition from "./Exposition";
import MapaModal from "./MapaModal";
import Criollo_fondo from "../../images/criollos/fondoLopez.jpg";
import Barrios_fondo from "../../images/criollos/fondoBarrios.jpg";
import Catedral_fondo from "../../images/criollos/fondoCatedral.jpg";
import Creditos_fondo from "../../images/criollos/fondoCreditos.jpg";
import Vallejos_fondo from "../../images/criollos/fondoVallejos.jpg";
import { Texts } from "./text";
import Lopez1 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto1-min.png";
import Lopez2 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto2-min.png";
import Lopez3 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto3-min.png";
import Lopez4 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto4-min.png";
import Lopez5 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto5-min.png";
import Lopez6 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto6-min.png";
import Lopez7 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto7-min.png";
import Lopez8 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto8-min.png";
import Lopez9 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto9-min.png";
import Lopez10 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto10-min.png";
import Lopez11 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto11-min.png";
import Lopez12 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto12-min.png";
import Lopez13 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto13-min.png";
import Lopez14 from "../../images/criollos/fotos_lopez/fotos_selecc_lopez_foto14-min.png";
import LopezAudio from "../../images/criollos/ROSA_LUZ_inst_Master_1.mp3";
import Barrios1 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto1-min.png";
import Barrios2 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto2-min.png";
import Barrios3 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto3-min.png";
import Barrios4 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto4-min.png";
import Barrios5 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto5-min.png";
import Barrios6 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto6-min.png";
import Barrios7 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto7-min.png";
import Barrios8 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto8-min.png";
import Barrios9 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto9-min.png";
import Barrios10 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto10-min.png";
import Barrios11 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto11-min.png";
import Barrios12 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto12-min.png";
import Barrios13 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto13-min.png";
import Barrios14 from "../../images/criollos/fotos_barrios/fotos_selecc_barrios_foto14-min.png";
import BarriosAudio from "../../images/criollos/El_Plebeyo_Master_Final_1.mp3";
import Catedral1 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto1-min.png";
import Catedral2 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto2-min.png";
import Catedral3 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto3-min.png";
import Catedral4 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto4-min.png";
import Catedral5 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto5-min.png";
import Catedral6 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto6-min.png";
import Catedral7 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto7-min.png";
import Catedral8 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto8-min.png";
import Catedral9 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto9-min.png";
import Catedral10 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto10-min.png";
import Catedral11 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto11-min.png";
import Catedral12 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto12-min.png";
import Catedral13 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto13-min.png";
import Catedral14 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto14-min.png";
import Catedral15 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto15-min.png";
import Catedral16 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto16-min.png";
import Catedral17 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto17-min.png";
import Catedral18 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto18-min.png";
import Catedral19 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto19-min.png";
import Catedral20 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto20-min.png";
import Catedral21 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto21-min.png";
import Catedral22 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto22-min.png";
import Catedral23 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto23-min.png";
import Catedral24 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto24-min.png";
import Catedral25 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto25-min.png";
import Catedral26 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto26-min.png";
import Catedral27 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto27-min.png";
import Catedral28 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto28-min.png";
import Catedral29 from "../../images/criollos/fotos_catedral/fotos_selecc_catedral_foto29-min.png";
import CatedralAudio from "../../images/criollos/AMELIA_master_final.mp3";

import Vallejos1 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto1-min.png";
import Vallejos2 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto2-min.png";
import Vallejos3 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto3-min.png";
import Vallejos4 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto4-min.png";
import Vallejos5 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto5-min.png";
import Vallejos6 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto6-min.png";
import Vallejos7 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto7-min.png";
import Vallejos8 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto8-min.png";
import Vallejos9 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto9-min.png";
import Vallejos10 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto10-min.png";
import Vallejos11 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto11-min.png";
import Vallejos12 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto12-min.png";
import Vallejos13 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto13-min.png";
import Vallejos14 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto14-min.png";
import Vallejos15 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto15-min.png";
import Vallejos16 from "../../images/criollos/fotos_vallejos/fotos_selecc_vallejos_Foto16-min.png";
import VallejosAudio from "../../images/criollos/LOCOS_SUSPIROS_Master_final.mp3";

import CriolloLopezResponsive from "../../images/criollos/criollo_fondo_titulo_lopez_responsive.png";
import CriolloBarriosResponsive from "../../images/criollos/criollo_fondo_titulo_barrios_responsive.png";
import CriolloCatedralResponsive from "../../images/criollos/criollo_fondo_titulo_catedral_responsive.png";
import CriolloVallejosResponsive from "../../images/criollos/criollo_fondo_titulo_vallejos_responsive.png";

import Layout from "../share/layout"
import { Helmet } from 'react-helmet';

const Criollos = () => {
  const handleChangePage = (to) => {
    const ContainerPortrait = document.getElementById("Portrait-id")
    const ContainerLopez = document.getElementById("Lopez-id")
    const ContainerBarrios = document.getElementById("Barrios-id")
    const ContainerCatedral = document.getElementById("Catedral-id")
    const ContainerVallejo = document.getElementById("Vallejo-id")
    const ContainerCreditos = document.getElementById("Creditos-id")
    const ContainerMapa = document.getElementById("Mapa-id")
    if ("Mapa" === to) {
      ContainerMapa.click()
    }
    if ("Portrait" === to) {
      ContainerPortrait.classList.add("ActivePage");
      Array.from(ContainerCreditos.querySelectorAll('.credit-animated')).map(node => node.classList.remove('animate__fadeInUp'))
      ContainerLopez.querySelector('.title').classList.remove("animate__fadeInDown");
      ContainerLopez.querySelector('.subtitle').classList.remove("animate__fadeInUp");
      ContainerLopez.querySelector('.description').classList.remove("animate__fadeInRight");
      ContainerBarrios.querySelector('.title').classList.remove("animate__fadeInDown");
      ContainerBarrios.querySelector('.subtitle').classList.remove("animate__fadeInUp");
      ContainerBarrios.querySelector('.description').classList.remove("animate__fadeInRight");
      ContainerCatedral.querySelector('.title').classList.remove("animate__fadeInDown");
      ContainerCatedral.querySelector('.subtitle').classList.remove("animate__fadeInUp");
      ContainerCatedral.querySelector('.description').classList.remove("animate__fadeInRight");
      ContainerVallejo.querySelector('.title').classList.remove("animate__fadeInDown");
      ContainerVallejo.querySelector('.subtitle').classList.remove("animate__fadeInUp");
      ContainerVallejo.querySelector('.description').classList.remove("animate__fadeInRight");
      ContainerLopez.classList.remove("ActivePage");
      ContainerBarrios.classList.remove("ActivePage");
      ContainerCatedral.classList.remove("ActivePage");
      ContainerVallejo.classList.remove("ActivePage");
      ContainerCreditos.classList.remove("ActivePage");
    }else{
      document.getElementById("video-pause").click() 
    }
    if ("Lopez" === to) {
      ContainerLopez.classList.add("ActivePage");
      ContainerLopez.querySelector('.title').classList.add("animate__fadeInDown");
      ContainerLopez.querySelector('.subtitle').classList.add("animate__fadeInUp");
      ContainerLopez.querySelector('.description').classList.add("animate__fadeInRight");
      ContainerPortrait.classList.remove("ActivePage");
      ContainerBarrios.classList.remove("ActivePage");
      ContainerCatedral.classList.remove("ActivePage");
      ContainerVallejo.classList.remove("ActivePage");
      ContainerCreditos.classList.remove("ActivePage");
    }
    if ("Barrios" === to) {
      ContainerBarrios.classList.add("ActivePage");
      ContainerBarrios.querySelector('.title').classList.add("animate__fadeInDown");
      ContainerBarrios.querySelector('.subtitle').classList.add("animate__fadeInUp");
      ContainerBarrios.querySelector('.description').classList.add("animate__fadeInRight");
      ContainerLopez.classList.remove("ActivePage");
      ContainerPortrait.classList.remove("ActivePage");
      ContainerCatedral.classList.remove("ActivePage");
      ContainerVallejo.classList.remove("ActivePage");
      ContainerCreditos.classList.remove("ActivePage");
    }
    if ("Catedral" === to) {
      ContainerCatedral.classList.add("ActivePage");
      ContainerCatedral.querySelector('.title').classList.add("animate__fadeInDown");
      ContainerCatedral.querySelector('.subtitle').classList.add("animate__fadeInUp");
      ContainerCatedral.querySelector('.description').classList.add("animate__fadeInRight");
      ContainerBarrios.classList.remove("ActivePage");
      ContainerLopez.classList.remove("ActivePage");
      ContainerPortrait.classList.remove("ActivePage");
      ContainerVallejo.classList.remove("ActivePage");
      ContainerCreditos.classList.remove("ActivePage");
    }
    if ("Vallejo" === to) {
      ContainerVallejo.classList.add("ActivePage");
      ContainerVallejo.querySelector('.title').classList.add("animate__fadeInDown");
      ContainerVallejo.querySelector('.subtitle').classList.add("animate__fadeInUp");
      ContainerVallejo.querySelector('.description').classList.add("animate__fadeInRight");
      ContainerCatedral.classList.remove("ActivePage");
      ContainerBarrios.classList.remove("ActivePage");
      ContainerLopez.classList.remove("ActivePage");
      ContainerPortrait.classList.remove("ActivePage");
      ContainerCreditos.classList.remove("ActivePage");
    }
    if ("Creditos" === to) {
      Array.from(ContainerCreditos.querySelectorAll('.credit-animated')).map(node => node.classList.add('animate__fadeInUp'))
      ContainerCreditos.querySelector('.title').classList.add("animate__fadeInLeft");
      ContainerCreditos.classList.add("ActivePage");
      ContainerVallejo.classList.remove("ActivePage");
      ContainerCatedral.classList.remove("ActivePage");
      ContainerBarrios.classList.remove("ActivePage");
      ContainerLopez.classList.remove("ActivePage");
      ContainerPortrait.classList.remove("ActivePage");
    }
  }
  return (
    <Layout
      title="Exhibición virtual – Criollos, Donde resiste la jarana - UPC Cultural"
      metaTitle="Exhibición virtual – Criollos, Donde resiste la jarana - UPC Cultural"
      description="Exhibición virtual Criollos, Donde resiste la jarana – UPC Cultural"
      subtitile="Exhibición virtual Criollos, Donde resiste la jarana – UPC Cultural"
      metaDescripcin="Exhibición virtual Criollos, Donde resiste la jarana – UPC Cultural"
      imgurl="//cultural.upc.edu.pe/static/portrait-93c938e178da92a9f8f699d36aaebdb2.png"
      keywords="Exhibición virtual, Criollos, Música Criolla, Música Peruana, Las Lopez, La Catedral del Criollismo, Luis Cáceres Álvarez, Fotografía, Perú, Lima"
    >
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
      </Helmet>
      <CriollosContainer>
        <Portrait
          handleChangePage={handleChangePage}
          className="Portrait"
        />
        <Exposition
          handleChangePage={handleChangePage}
          className="Lopez"
          title="LAS LÓPEZ"
          subtitle="Una peña familiar"
          background={Criollo_fondo}
          text={Texts.Lopez}
          galleryImages={[
            Lopez1,
            Lopez2,
            Lopez3,
            Lopez4,
            Lopez5,
            Lopez6,
            Lopez7,
            Lopez8,
            Lopez9,
            Lopez10,
            Lopez11,
            Lopez12,
            Lopez13,
            Lopez14
          ]}
          logoResponsive={CriolloLopezResponsive}
          audio={LopezAudio}
        />
        <Exposition
          handleChangePage={handleChangePage}
          className="Barrios"
          background={Barrios_fondo}
          title="Barrios Altos"
          subtitle="Cuna de tradiciones"
          text={Texts.Barrios}
          galleryImages={[
            Barrios1,
            Barrios2,
            Barrios3,
            Barrios4,
            Barrios5,
            Barrios6,
            Barrios7,
            Barrios8,
            Barrios9,
            Barrios10,
            Barrios11,
            Barrios12,
            Barrios13,
            Barrios14
          ]}
          audio={BarriosAudio}
          logoResponsive={CriolloBarriosResponsive}
        />
        <Exposition
          handleChangePage={handleChangePage}
          className="Catedral"
          background={Catedral_fondo}
          title="La Catedral del Criollismo"
          subtitle="Guardia Vieja del siglo XXI"
          text={Texts.Catedral}
          galleryImages={[
            Catedral1,
            Catedral2,
            Catedral3,
            Catedral4,
            Catedral5,
            Catedral6,
            Catedral7,
            Catedral8,
            Catedral9,
            Catedral10,
            Catedral11,
            Catedral12,
            Catedral13,
            Catedral14,
            Catedral15,
            Catedral16,
            Catedral17,
            Catedral18,
            Catedral19,
            Catedral20,
            Catedral21,
            Catedral22,
            Catedral23,
            Catedral24,
            Catedral25,
            Catedral26,
            Catedral27,
            Catedral28,
            Catedral29
          ]}
          audio={CatedralAudio}
          logoResponsive={CriolloCatedralResponsive}
        />
        <Exposition
          handleChangePage={handleChangePage}
          className="Vallejo"
          background={Vallejos_fondo}
          title="Vallejos Bozzo"
          subtitle="La jarana de miércoles"
          text={Texts.Vallejo}
          galleryImages={[
            Vallejos1,
            Vallejos2,
            Vallejos3,
            Vallejos4,
            Vallejos5,
            Vallejos6,
            Vallejos7,
            Vallejos8,
            Vallejos9,
            Vallejos10,
            Vallejos11,
            Vallejos12,
            Vallejos13,
            Vallejos14,
            Vallejos15,
            Vallejos16
          ]}
          audio={VallejosAudio}
          logoResponsive={CriolloVallejosResponsive}
        />
        <MapaModal
          handleChangePage={handleChangePage}
          background={Criollo_fondo}
        />
        <Exposition
          className="Creditos"
          handleChangePage={handleChangePage}
          title="Créditos"
          subtitle=""
          background={Creditos_fondo}
          galleryImages={[]}
        />
      </CriollosContainer>
    </Layout>
  )
}

export default Criollos
