import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import botonDerechaOff from "../../images/criollos/criollo_boton_flecha_derecha_sombra_off.png";
import botonDerechaOn from "../../images/criollos/criollo_boton_flecha_derecha_sombra_on.png";
import botonIzquierdaOff from "../../images/criollos/criollo_boton_flecha_izquierda_sombra_off.png";
import botonIzquierdaOn from "../../images/criollos/criollo_boton_flecha_izquierda_sombra_on.png";

const CarouselGallery = ({ galleryImages, className }) => {
  const PrevSlide = () => {
    document.querySelector(`.${className}-1`).getElementsByClassName("control-prev")[0].click()
  }
  const NextSlide = () => {
    document.querySelector(`.${className}-1`).getElementsByClassName("control-next")[0].click()
  }
  return (
    <>
      <button onClick={PrevSlide} className="slider-button izquierda">
        <img className="first" alt="Criollos-boton-Izquierda-Off" src={botonIzquierdaOff} />
        <img className="last" alt="Criollos-boton-Izquierda-On" src={botonIzquierdaOn} />
      </button>
      <Carousel
        interval={10000}
        emulateTouch={false}
        transitionTime={800}
        showThumbs={false}
        showArrows={true}
        infiniteLoop={true}
        showStatus={false}
      >
        {galleryImages.map((image, index) => (
          <div
            key={index} className={"carousel-image-container animate__animated "/* + (currentSlide === index && currentSlide !== 0 ? "animate__zoomOut" : "animate__zoomIn ")*/}>
            <img src={image} alt={`Criollos-${image}`} />
          </div>
        ))}
      </Carousel>
      <button onClick={NextSlide} className="slider-button derecha">
        <img className="first" alt="Criollos-boton-Derecha-Off" src={botonDerechaOff} />
        <img className="last" alt="Criollos-boton-Derecha-On" src={botonDerechaOn} />
      </button>
    </>
  )
}

export default CarouselGallery
