import React, { useEffect, useRef } from 'react';
import "video-react/dist/video-react.css";
import { Player, ControlBar } from "video-react";
import ImagePortrait from "../../images/criollos/portrait.jpg";
import videoCriollos from "./criollos.mp4";

const MediaPlayer = ({ valid }) => {
  let playerRef = useRef(null);
  useEffect(() => {
    if (playerRef) {
      playerRef.subscribeToStateChange((event) => {
        const target = document.getElementById("criollos-image")
        if (target.classList.contains("enable_animation")) {
          if (event.paused) {
            target.classList.add("enable_animation-2")
            if (target.classList.contains("enable_animation-2")) {
              target.classList.remove("opacity-0", "animate__fadeOutUp", "animate__delay-3s")
              target.classList.add("animate__fadeInDown")
            }
          } else {
            if (target.classList.contains("enable_animation-2")) {
              target.classList.remove("animate__fadeInDown")
              target.classList.add("animate__fadeOutUp", "animate__delay-3s")
            }
          }
        }
      })
    }
    setTimeout(() => {
      document.getElementById("criollos-image").classList.add("enable_animation")
    }, 2000);
  }, [playerRef])

  const PlayPauseVideo = (type) => {
    if (type === 1) {
      playerRef.play();
    } else if (type === 2) {
      playerRef.pause();
    }
  }
  return (
    <>
      <button onClick={() => PlayPauseVideo(3)} style={{ display: 'none' }} id="video-muted">Muted</button>
      <button onClick={() => PlayPauseVideo(1)} style={{ display: 'none' }} id="video-play">play</button>
      <button onClick={() => PlayPauseVideo(2)} style={{ display: 'none' }} id="video-pause">pause</button>
      <Player
        ref={player => { playerRef = player }}
        poster={ImagePortrait}
      >
        <source
          src={videoCriollos}
          type="video/mp4"
        />
        <ControlBar
          autoHideTime={800}
        />
      </Player>
    </>
  )
}

export default MediaPlayer
