import React from 'react'
import SmokeAnimation from './SmokeAnimation';
import { ExpositionContainer } from "./styles";
import Criollo_boton_inicio_off from "../../images/criollos/criollo_boton_inicio_off.png";
import Criollo_boton_inicio_on from "../../images/criollos/criollo_boton_inicio_on.png";
import Criollo_boton_galeria_off from "../../images/criollos/criollo_boton_galeria_off.png";
import Criollo_boton_galeria_on from "../../images/criollos/criollo_boton_galeria_on.png";
import Gallery from "./Gallery.js";

const Exposition = ({
  background,
  handleChangePage,
  title,
  galleryImages,
  subtitle,
  text,
  className,
  audio,
  logoResponsive,
  referencia
}) => {
  const handleShowGallery = (isOpen) => {
    const GalleryRef = document.getElementById(`${className}-gallery-id`)
    if (isOpen) {
      document.getElementById(`${className}-play`).click()
      GalleryRef.classList.remove('animate__fadeOut')
      GalleryRef.classList.add('ActiveGalleryPage', 'animate__fadeInUp')
    } else {
      document.getElementById(`${className}-pause`).click()
      GalleryRef.classList.add('animate__fadeOut')
      GalleryRef.classList.remove('ActiveGalleryPage', 'animate__fadeInUp')
    }
  };
  return (
    <ExpositionContainer useRef={referencia} id={`${className}-id`} title={title} background={background}>
      <SmokeAnimation />
      <div className="ExpositionSubcontainer">
        <button onClick={() => handleChangePage('Portrait')} className="back-button animate__animated animate__fadeInDown">
          <img className="firts-image" alt="criollos-boton-inicio-off" src={Criollo_boton_inicio_off} />
          <img className="last-image" alt="criollos-boton-inicio-on" src={Criollo_boton_inicio_on} />
        </button>
        <div className="text-container">
          <div className="first-subcontainer">
            <h2 className="title animate__animated">{title}</h2>
            <h3 className="subtitle animate__animated">{subtitle}</h3>
          </div>
          <div className="last-subcontainer">
            {title === "Créditos" ?
              <div className="container-credits mb-4">
                <h4 className="credit-animated animate__animated">Registro fotográfico y de video:</h4>
                <h5 className="credit-animated animate__animated">Archivo de Luis Cáceres Álvarez</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "1s" }}>Textos:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "1s" }}>Luis Cáceres Álvarez</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "2s" }}>Música:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "2s" }}>Felipe Pinglo Alva</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "3s" }}>Interpretación, mezcla y masterización:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "3s" }}>Carlos Ayala</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "4s" }}>Temas musicales:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "4s" }}>En portada: Las Esdrújulas<br />En Las López: Rosaluz<br />En Barrios Altos: El Plebeyo<br />En La Catedral del Criollismo: Amelia<br />En Vallejos-Bozzo: Locos suspiros</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "5s" }}>Voz en off en video de portada:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "5s" }}>Augusto Casafranca</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "6s" }}>Curaduría:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "6s" }}>Luis Cáceres Álvarez y Pepe Santana</h5>
                <h4 className="credit-animated mt-2 animate__animated" style={{ animationDelay: "7s" }}>Producción ejecutiva:</h4>
                <h5 className="credit-animated animate__animated" style={{ animationDelay: "7s" }}>Pepe Santana</h5>
              </div> :
              <p className="description animate__animated">{text}</p>
            }
            {galleryImages.length !== 0 &&
              <div className="gallery-button-container animate__animated animate__fadeInUp">
                <button className="gallery-button" onClick={() => handleShowGallery(true)}>
                  <img className="firts-image" alt="criollo-boton-galeria-off" src={Criollo_boton_galeria_off} />
                  <img className="last-image" alt="criollo-boton-galeria-on" src={Criollo_boton_galeria_on} />
                </button>
              </div>
            }
          </div>
        </div>
      </div>
      {galleryImages.length !== 0 &&
        <Gallery
          logoResponsive={logoResponsive}
          galleryImages={galleryImages}
          audio={audio}
          className={className}
          handleShowGallery={() => handleShowGallery(false)}
        />
      }
    </ExpositionContainer>
  )
}

export default Exposition
