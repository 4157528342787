import React, { useEffect, useState } from 'react';
import { SmokeContainer } from "./smokeStyle";

const SmokeAnimation = () => {
  const [Animation, setAnimation] = useState(false)
  useEffect(() => {
    if(navigator.userAgent){
      if(!navigator.userAgent.match(/(iPad|iPhone|iPod)/g)){
        setAnimation(true)
      }
    }
  }, [Animation, setAnimation])
  if(!Animation){
    return null
  }
  return (
    <SmokeContainer>
      <section className="ag-smoke-block">
        <div className="ag-smoke-1"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-1 ag-smoke__delay-1"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-1 ag-smoke__delay-2"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-2"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-2 ag-smoke__delay-1"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-2 ag-smoke__delay-2"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-4"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-4 ag-smoke__delay-1"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-smoke-4 ag-smoke__delay-2"><img alt="smoke-animation-criollos" src="https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-smoke-img/images/smoke-4.png" /></div>
        <div className="ag-format-container">
        </div>
      </section>
    </SmokeContainer>
  )
}

export default SmokeAnimation
