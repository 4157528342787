import React, { useState } from 'react'
import { CriollosModal } from "./styles";
import Criollo_barra_mapa_cerrar_on from "../../images/criollos/criollo_barra_mapa_cerrar_on.png";
import Criollo_barra_mapa_cerrar_off from "../../images/criollos/criollo_barra_mapa_cerrar_off.png";

const MapaModal = () => {
  const [showMap, setShowMap] = useState(false)
  return (
    <>
    <button aria-label="button-modal" style={{display: 'none'}} id="Mapa-id" onClick={() => setShowMap(true)}></button>
    <CriollosModal
      backdrop={true}
      show={showMap}
      centered={true}
      onHide={() => setShowMap(false)}
    >
      <button onClick={() => setShowMap(false)}>
        <img className="first" alt="Criollo_barra_mapa_cerrar_on" src={Criollo_barra_mapa_cerrar_on} />
        <img className="last" alt="Criollo_barra_mapa_cerrar_off" src={Criollo_barra_mapa_cerrar_off} />
      </button>
      <div className="iframe-container">
        <iframe title="Criollos Mapa" src="https://www.google.com/maps/d/embed?mid=1-D5KpNNcUFSwgDfbDA6IyRXBFoOZaVzp" width="640" height="480"></iframe>
      </div>
    </CriollosModal>
    </>
  )
}

export default MapaModal
